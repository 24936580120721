'use strict';var _typeof=typeof Symbol==='function'&&typeof Symbol.iterator==='symbol'?function(obj){return typeof obj}:function(obj){return obj&&typeof Symbol==='function'&&obj.constructor===Symbol&&obj!==Symbol.prototype?'symbol':typeof obj};/* global site */window.site=window.site||{};/**
 * Main application class.
 * @class App
 * @static
 */window.site.App=function App(){/**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */var config={env:'production',csrf:null,csrfName:null,locale:'en',device:'desktop',preview:false,general:{}};/**
   * Initializes the class.
   * @public
   */var init=function init(options){// Abort if already initialized
if(inited){return false}inited=true;// Store application settings
options=options||{};if(options.env){config.env=options.env}if(options.csrf){config.csrf=options.csrf}if(options.csrfName){config.csrfName=options.csrfName}if(options.locale){config.locale=options.locale}if(options.device){config.device=options.device}if(options.preview){config.preview=options.preview}if(options.general){config.general=options.general}// Initialize child classes
// if (typeof site.ShareButton === 'object') { site.ShareButton.init(); }
if(_typeof(site.AjaxPagination)==='object'){site.AjaxPagination.init()}if(_typeof(site.MenuMobile)==='object'){site.MenuMobile.init()}// if (typeof site.CookiePolicy === 'object') { site.CookiePolicy.init(); }
if(_typeof(site.scrollTo)==='object'){site.scrollTo.init()}// if (typeof site.Map === 'object') { site.Map.init(); }
// if (typeof site.GeolocationServices === 'object') { site.GeolocationServices.init(); }
// Detect css-grid un-supported
var el=document.createElement('div');var supportsGrid='string'==typeof el.style.grid;if(!supportsGrid){var body=document.getElementsByTagName('body')[0].classList.add('css-grid-unsupported')}// PreRender for fast website
function prerenderlink(e){console.log(e,e.currentTarget,e.currentTarget.href);var head=document.getElementsByTagName('head')[0];var refs=head.childNodes;ref=refs[refs.length-1];var elements=head.getElementsByTagName('link');Array.prototype.forEach.call(elements,function(el,i){if('rel'in el&&el.rel==='prerender')el.parentNode.removeChild(el)});var prerenderTag=document.createElement('link');prerenderTag.rel='prerender';prerenderTag.href=e.currentTarget.href;ref.parentNode.insertBefore(prerenderTag,ref)}function unloadBeforeGoTo(e){e.preventDefault();setTimeout(function(){document.querySelector('#site-container').classList.remove('is-loaded')},1);setTimeout(function(){window.location.href=e.target.href},1275)}var $prerenderLinks=document.querySelectorAll('.header-link ');[].slice.call($prerenderLinks).forEach(function(elem){elem.addEventListener('mouseenter',prerenderlink)});[].slice.call($prerenderLinks).forEach(function(elem){elem.addEventListener('click',unloadBeforeGoTo)});// Load Animations
setTimeout(function(){document.querySelector('#site-container').classList.add('is-loaded')},1);setTimeout(function(){var $slider=document.querySelector('[data-active-flickity]');if($slider){// Flickity
var flkty=new Flickity($slider,{'cellAlign':'left','contain':true,'pageDots':false,'fullscreen':true,'wrapAround':true})}setTimeout(function(){// Refresh Flickity
flkty.resize()},755)},755);console.table(options);// Return success
return true};/**
   * Getter for application config.
   * @public
   */var getConfig=function getConfig(option){return config[option]?config[option]:false};/**
   * Expose public methods & properties.
   */return{init:init,config:getConfig}}();